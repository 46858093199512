<template>
  <div class="container">
      <Icon type="ios-warning-outline" />
      <span>该功能未开通，请联系管理员！</span>
  </div>
</template>

<script>
export default {
    name: 'Guide',
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
}
span {
    font-size: 20px;
}
</style>