<template>
  <div class="container">
      <Tabs value="ip" :animated="false">
        <TabPane label="ip白名单" name="ip">
            <Form :model="formObj" label-position="left" :label-width="100" label-colon>
                <FormItem label="启用IP白名单">
                    <i-switch v-model="formObj.enable" size="large">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </FormItem>
                <FormItem label="IP列表">
                    <Input type="textarea" v-model="formObj.list" :rows="5" placeholder="请输入IP，一行一个IP"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="save">保存</Button>
                </FormItem>
            </Form>
        </TabPane>
      </Tabs>
  </div>
</template>

<script>
export default {
    name: 'SysConf',
    data() {
        return {
            formObj: {
                enable: false,
                list: ''
            }
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    },
    methods: {
        _fetch() {
            this.apiV1({
                method: 'get',
                url: '/settings/ipWhitelist',
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    const list = data.list.join('\n');
                    this.formObj = {
                        enable: data.enable,
                        list
                    };
                }
            });
        },
        save() {
            const list = this.formObj.list.split('\n').map(elem => elem.trim()).filter(elem => !!elem);
            this.apiV1({
                method: 'post',
                url: '/settings/ipWhitelist',
                data: {
                    enable: this.formObj.enable,
                    list
                },
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Message.success('保存成功');
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
}
</style>