<template>
  <div class="container">
    <Form :model="formObj" label-position="left" :label-width="80">
        <FormItem label="手动输入">
            <Input type="textarea" v-model="formObj.manualInput" :rows="5" placeholder="请输入手机号码，一行一个号码"></Input>
            <p class="rule">*格式必须为:国际区号+号码。如：8613900000000,区号与号码之间不加空格</p>
            <p class="rule">*大批量发送请使用文件导入，文件仅支持TxT文本，格式必须区号+号码，区号与号码之间不加空格”。</p>
            <p class="rule">*中国大陆地区短信必须提前跟客户经理进行报备审核后才能发送。</p>
            <p class="rule">*手动输入最大支持500个手机号码，超过500请使用TxT文本批量导入。</p>
            <p class="rule">*提交时会自动过滤无效号码。</p>
        </FormItem>
        <Button type="primary" size="small" @click="batchImport1">TXT文本批量导入</Button>
        <span v-if="formObj.fileName" style="margin-left:10px;color:#999;">{{formObj.fileName}}（号码数量：{{formObj.batchImport.length}}）</span>
        <input id="file-input" type="file" name="name" style="display: none;" @change="batchImport2"/>
        <br><br>
        <Tooltip placement="top-start" theme="light" max-width="400">
            <div slot="content">
                <h1>txt示例</h1>
                <p> 8617305921234</p>
                <p> 8617305921235</p>
                <p> 8617305921236</p>
                <p style="color:#999;">*超过10万条号码以上的文件，建议分批次上传发送</p>
            </div>
            <a>TXT导入示例</a>
        </Tooltip>
        <br><br>
        <FormItem label="发送时间">
            <RadioGroup v-model="formObj.sendTimeMode">
                <Radio label="immediate">立即发送</Radio>
                <Radio label="timing">定时发送</Radio>
            </RadioGroup>
            <template v-if="formObj.sendTimeMode === 'timing'">
                <br>
                <DatePicker type="datetime" placeholder="自定义发送时间" style="width: 200px;" v-model="formObj.timing"></DatePicker>
            </template>
        </FormItem>
        <FormItem label="发送内容">
            <Input type="textarea" v-model="formObj.content" :rows="5" placeholder="请输入发送内容"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="submit">提交</Button>
            <Button style="margin-left: 8px;" @click="reset">重置</Button>
        </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
    name: 'BatchSend',
    data() {
        return {
            formObj: {
                manualInput: '',
                fileName: '',
                batchImport: [],
                sendTimeMode: 'immediate',
                timing: '',
                content: ''
            }
        }
    },
    mounted() {
        this.token = this.$store.state.token;
    },
    methods: {
        batchImport1() {
            document.getElementById('file-input').click();
        },
        batchImport2() {
            const file = document.getElementById('file-input').files[0];
            // const task_name = file.name.split('.')[0];
            if (file) {
                this.formObj.fileName = file.name;
                const reader = new FileReader();
                reader.readAsText(file, "utf8");
                reader.onload = () => {
                    const data = reader.result.split('\n').map(elem => elem.trim()).filter(elem => elem.match(/^\d+$/));
                    this.formObj.batchImport = data;
                }
            }
        },
        submit() {
            const phoneNumberArr = [...this.formObj.batchImport];
            if (this.formObj.manualInput) {
                const arr = this.formObj.manualInput.split('\n').map(elem => elem.trim());
                const numbers = arr.filter(elem => elem.match(/^\d+$/)).slice(0, 500);
                phoneNumberArr.push(...numbers);
            }
            if (!phoneNumberArr.length) {
                this.$Message.error('请输入手机号');
                return;
            }
            if (!this.formObj.content) {
                this.$Message.error('请输入发送内容');
                return;
            }
            let sendTime;
            if (this.formObj.sendTimeMode === 'immediate') {
                sendTime = new Date();
            } else {
                sendTime = this.formObj.timing;
            }
            sendTime = this.moment(sendTime).format('YYYY-MM-DD HH:mm:ss');
            this.apiV1({
                method: 'post',
                url: '/sms/batchSend',
                data: {
                    phoneNumberArr,
                    sendTime,
                    content: this.formObj.content
                },
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success } = response.data;
                if (success) {
                    // this.$Message.success('提交成功');
                    this.$Notice.success({
                        title: '提交成功'
                    });
                    this.reset();
                    this.refreshBalance();
                }
            });
        },
        reset() {
            this.formObj = {
                manualInput: '',
                fileName: '',
                batchImport: [],
                sendTimeMode: 'immediate',
                timing: '',
                content: ''
            };
            const input = document.getElementById('file-input');
            input.value = '';
        },
        refreshBalance() {
            this.apiV1({
                method: 'get',
                url: '/uinfo',
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.$store.state.uinfo.balance = data.balance;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
    .rule {
        color: #062ff7;
    }
}
</style>