<template>
  <div class="container">
      <Tabs value="online" :animated="false" @on-click="onClickTab">
        <TabPane label="在线充值" name="online">
            <Form v-model="formObj" label-position="left" :label-width="100" label-colon>
                <FormItem label="选择支付方式">
                    <RadioGroup v-model="formObj.mode">
                        <Radio label="alipay">支付宝支付</Radio>
                        <Radio label="wxpay">微信支付</Radio>
                        <Radio label="other">其他</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem label="充值金额">
                    <Input type="number" placeholder="请输入充值金额" style="width:300px;">
                    </Input>
                </FormItem>
                <FormItem label="账户余额￥">
                    {{$store.state.uinfo.balance}}
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="toRecharge">前往支付</Button>
                </FormItem>
            </Form>
        </TabPane>
        <TabPane label="充值记录" name="record">
            <DatePicker transfer v-model="searchObj.inRange" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="选择时间范围" style="width: 300px"></DatePicker>
            <Button type="primary" @click="_fetch">查询</Button>
            <Divider dashed />
            <div style="width:1301px;">
                <Table :columns="columns" :data="data.rows" :loading="tableLoading">
                    <template slot-scope="{ row }" slot="status">
                        <Badge :status="statusMap[row.status].color" />
                        <span>{{statusMap[row.status].text}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="mode">
                        <span>{{modeMap[row.type]}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="payMethod">
                        <span>N/A</span>
                    </template>
                </Table>
                <!-- 分页 -->
                <Page
                    :total="data.count"
                    :current="searchObj.page"
                    :page-size="searchObj.size"
                    show-total
                    show-elevator
                    @on-change="onPageChange"
                    transfer
                    style="margin:24px 0;text-align:center;"/>
            </div>
        </TabPane>
      </Tabs>
  </div>
</template>

<script>
export default {
    name: 'Recharge',
    data() {
        return {
            formObj: {
                mode: 'alipay'
            },
            data: {
                count: 0,
                rows: []
            },
            searchObj: {
                inRange: [],
                page: 1,
                size: 10
            },
            columns: [
                {
                    type: 'index',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '充值金额',
                    key: 'amount',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '订单状态',
                    slot: 'status',
                    width: 200
                },
                {
                    title: '充值渠道',
                    slot: 'mode',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '支付方式',
                    slot: 'payMethod',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '创建时间',
                    key: 'createdAt',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '更新时间',
                    key: 'updatedAt',
                    width: 200,
                    align: 'center'
                }
            ],
            tableLoading: false,
            statusMap: {
                0: { color: 'warning', text: '待支付' },
                1: { color: 'success', text: '充值成功' }
            },
            modeMap: {
                0: '在线充值',
                1: '人工充值'
            }
        }
    },
    mounted() {
        this.token = this.$store.state.token;
    },
    methods: {
        _fetch() {
            this.tableLoading = true;
            const searchObjCopy = Object.assign({}, this.searchObj);
            if (searchObjCopy.inRange.length === 2 && !searchObjCopy.inRange.some(elem => !elem)) {
                searchObjCopy.inRange = this.searchObj.inRange.map(elem => this.moment(elem).format('YYYY-MM-DD HH:mm:ss')).join(',');
            } else {
                searchObjCopy.inRange = '';
            }
            this.apiV1({
                method: 'get',
                url: '/recharge/records',
                params: searchObjCopy,
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.data = data;
                }
            }).finally(() => this.tableLoading = false);
        },
        toRecharge() {
            this.apiV1({
                method: 'post',
                url: '/recharge/online',
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.$Modal.info({
                        title: 'E快讯',
                        content: data
                    });
                }
            });
        },
        onClickTab(name) {
            if (name === 'record') {
                this._fetch();
            }
        },
        onPageChange(page) {
            this.searchObj.page = page;
            this._fetch();
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
}
</style>