<template>
  <div class="container">
      <DatePicker v-model="searchObj.inRange" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="选择时间范围" style="width: 300px"></DatePicker>
      <Button type="primary" @click="_fetch">查询</Button>
      <Divider dashed />
      <Table :columns="columns" :data="data.rows" :loading="tableLoading">
        <template slot-scope="{ row }" slot="status">
            <Badge :status="statusMap[row.status].color" />
            <span>{{statusMap[row.status].text}}</span>
        </template>
        <template slot-scope="{ row }" slot="sendTime">
            {{moment(row.sendTime).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <!-- <template slot-scope="{ row }" slot="action">
        </template> -->
      </Table>
    <!-- 分页 -->
    <Page
        :total="data.count"
        :current="searchObj.page"
        :page-size="searchObj.size"
        show-total
        show-elevator
        @on-change="onPageChange"
        transfer
        style="margin:24px 0;text-align:center;"/>
  </div>
</template>

<script>
export default {
    name: 'OrdRecord',
    data() {
        return {
            searchObj: {
                inRange: [],
                page: 1,
                size: 10
            },
            data: {
                count: 0,
                rows: []
            },
            tableLoading: false,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '状态',
                    slot: 'status',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '审核意见',
                    key: 'rejectReason',
                    width: 150,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '发送时间',
                    slot: 'sendTime',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '发送内容',
                    key: 'content',
                    width: 200,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '本次消费金额',
                    key: 'money',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '本次文档条数',
                    key: 'countTotal',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '发送成功条数',
                    key: 'countOk',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '发送失败条数',
                    key: 'countNo',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '创建时间',
                    key: 'createdAt',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '更新时间',
                    key: 'updatedAt',
                    width: 200,
                    align: 'center'
                },
                // {
                //     title: '操作',
                //     slot: 'action',
                //     align: 'center',
                //     width: 200,
                //     fixed: 'right'
                // }
            ],
            statusMap: {
                0: { color: 'warning', text: '审核中' },
                1: { color: 'error', text: '审核拒绝' },
                2: { color: 'default', text: '等待发送' },
                3: { color: 'processing', text: '发送中' },
                4: { color: 'success', text: '完成' }
            }
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    },
    methods: {
        _fetch() {
            this.tableLoading = true;
            const searchObjCopy = Object.assign({}, this.searchObj);
            if (searchObjCopy.inRange.length === 2 && !searchObjCopy.inRange.some(elem => !elem)) {
                searchObjCopy.inRange = this.searchObj.inRange.map(elem => this.moment(elem).format('YYYY-MM-DD HH:mm:ss')).join(',');
            } else {
                searchObjCopy.inRange = '';
            }
            this.apiV1({
                method: 'get',
                url: '/sms/sendRecords',
                params: searchObjCopy,
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.data = {
                        count: data.count,
                        rows: data.rows.map(elem => {
                            elem.rejectReason = elem.rejectReason || 'N/A';
                            return elem;
                        })
                    };
                }
            }).finally(() => this.tableLoading = false);
        },
        onPageChange(page) {
            this.searchObj.page = page;
            this._fetch();
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
}
</style>