<template>
  <div class="container">
      <Tabs value="userConf" :animated="false">
        <TabPane label="账号设置" name="userConf">
            <Form :model="formObj" label-position="left" :label-width="100" label-colon>
                <FormItem label="昵称">
                    <Input type="text" v-model="formObj.nickname" placeholder="请输入昵称（选填）" style="width:300px;" clearable></Input>
                </FormItem>
                <FormItem label="备用联系人">
                    <Input type="text" v-model="formObj.spareContactName" placeholder="请输入备用联系人（选填）" style="width:300px;" clearable></Input>
                </FormItem>
                <FormItem label="联系人手机">
                    <Input type="text" v-model="formObj.spareContactPhone" placeholder="请输入联系人手机（选填）" style="width:300px;" clearable></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="save">保存</Button>
                </FormItem>
            </Form>
        </TabPane>
        <TabPane label="密码修改" name="resetLoginPw">
            <Form ref="forget-form" class="form" :model="forgetPwFormObj" :rules="ruleValidateForget">
                <FormItem prop="phoneNumber">
                    <div>
                        <Input type="text" v-model="forgetPwFormObj.phoneNumber" clearable placeholder="请输入手机号" style="width:300px;"></Input>
                    </div>
                </FormItem>
                <FormItem prop="captcha">
                    <Input type="text" v-model="forgetPwFormObj.captcha" placeholder="请输入短信验证码" style="width:300px;">
                        <Button style="width:102px;" :disabled="countdown > 0" slot="append" @click="sendSmsCode(null, forgetPwFormObj.phoneNumber)">{{countdown > 0 ? countdown + 's' : '获取验证码'}}</Button>
                    </Input>
                </FormItem>
                <FormItem prop="loginPw">
                    <Input type="password" v-model="forgetPwFormObj.loginPw" clearable placeholder="请输入新密码" style="width:300px;"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetLoginPw">确认提交</Button>
                </FormItem>
            </Form>
        </TabPane>
      </Tabs>
  </div>
</template>

<script>
const sha1 = require('sha1');
export default {
    name: 'UserConf',
    data() {
        return {
            formObj: {
                nickname: '',
                spareContactName: '',
                spareContactPhone: ''
            },
            forgetPwFormObj: {
                phoneNumber: '',
                captcha: '',
                loginPw: ''
            },
            ruleValidateForget: {
                phoneNumber: [
                    { required: true, message: '请输入手机号' }
                ],
                captcha: [
                    { required: true, message: '请输入验证码' }
                ],
                loginPw: [
                    { required: true, message: '请输入密码' }
                ]
            },
            countdown: 0
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this.formObj = {
            nickname: this.$store.state.uinfo.nickname,
            spareContactName: this.$store.state.uinfo.spareContactName,
            spareContactPhone: this.$store.state.uinfo.spareContactPhone
        };
        this.forgetPwFormObj.phoneNumber = this.$store.state.uinfo.phoneNumber;
    },
    methods: {
        save() {
            this.apiV1({
                method: 'post',
                url: '/settings/spareContact',
                data: this.formObj,
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Message.success('保存成功');
                    this.$store.state.uinfo.nickname = this.formObj.nickname;
                    this.$store.state.uinfo.spareContactName = this.formObj.spareContactName;
                    this.$store.state.uinfo.spareContactPhone = this.formObj.spareContactPhone;
                }
            });
        },
        sendSmsCode(areaCode, phoneNumber) {
            if (!phoneNumber) {
                this.$Message.error('请输入手机号');
                return;
            }
            const t = Date.now();
            const sign = sha1(areaCode + phoneNumber + t + 'zkj3219ak1m');
            this.apiV1({
                method: 'post',
                url: '/sendSmsCode',
                data: {
                    t,
                    areaCode,
                    phoneNumber,
                    sign
                }
            }).then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '验证码已发送，请注意查收'
                    });
                    this.countdown = 60;
                    const timer = setInterval(() => {
                        this.countdown--;
                        if (this.countdown === 0) {
                            clearInterval(timer);
                        }
                    }, 1000);
                }
            });
        },
        resetLoginPw() {
            this.$refs['forget-form'].validate((valid) => {
                if (valid) {
                    this.apiV1({
                        method: 'post',
                        url: '/resetLoginPw',
                        data: this.forgetPwFormObj
                    }).then(response => {
                        const { success, data } = response.data;
                        if (success) {
                            this.$store.commit('login', data);
                            localStorage.setItem('token', data.token);
                            this.token = data.token;
                            this.$Message.success('密码已重置');
                        }
                    });
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
}
</style>