<template>
  <div class="container">
      <DatePicker v-model="searchObj.inRange" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="选择时间范围" style="width: 300px"></DatePicker>
      <Button type="primary" @click="_fetch">查询</Button>
      <Divider dashed />
      <Table :columns="columns" :data="data" :loading="tableLoading" width="601"></Table>
  </div>
</template>

<script>
export default {
    name: 'DataAnalysis',
    data() {
        return {
            data: [],
            tableLoading: false,
            columns: [
                {
                    title: '国际电话区号',
                    key: 'areaCode',
                    width: 300,
                    align: 'center'
                },
                {
                    title: '发送量',
                    key: 'count',
                    width: 300,
                    align: 'center'
                },
            ],
            searchObj: {
                inRange: []
            }
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    },
    methods: {
        _fetch() {
            this.tableLoading = true;
            const searchObjCopy = Object.assign({}, this.searchObj);
            if (searchObjCopy.inRange.length === 2 && !searchObjCopy.inRange.some(elem => !elem)) {
                searchObjCopy.inRange = this.searchObj.inRange.map(elem => this.moment(elem).format('YYYY-MM-DD HH:mm:ss')).join(',');
            } else {
                searchObjCopy.inRange = '';
            }
            this.apiV1({
                method: 'get',
                url: '/sms/analysis',
                params: searchObjCopy,
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.data = data;
                }
            }).finally(() => this.tableLoading = false);
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    height: 100%;
}
</style>